import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clear,
  play,
  selectStatus,
  quickPick,
  samePick
} from "../slices/gameSlice";

const Controls = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const active = "bg-green-600 text-white hover:bg-green-900";
  const inactive = "bg-gray-100 text-gray-400 cursor-default";
  let playLabel = "Play";
  let quickStyle;
  let sameStyle;
  let eraseStyle;
  let playStyle;
  switch (status) {
    case "new":
      quickStyle = active;
      sameStyle = inactive;
      eraseStyle = inactive;
      playStyle = inactive;
      break;
    case "ready":
    case "picking":
      quickStyle = inactive;
      sameStyle = inactive;
      eraseStyle = active;
      playStyle = active;
      break;
    case "roundOver":
      quickStyle = active;
      sameStyle = active;
      eraseStyle = active;
      playStyle = inactive;
      break;
    case "next":
      quickStyle = active;
      sameStyle = inactive;
      eraseStyle = inactive;
      playStyle = inactive;
      break;
    case "gameOver":
      quickStyle = inactive;
      sameStyle = inactive;
      eraseStyle = inactive;
      playStyle = active;
      playLabel = "New Game";
      break;
    default:
      break;
  }

  return (
    <div className="w-full px-5 mx-auto">
      <div className="grid grid-cols-4 gap-1">
        <button
          className={quickStyle + " font-bold py-2 focus:outline-none rounded"}
          onClick={() => dispatch(quickPick())}
        >
          Quick Pick
        </button>
        <button
          className={sameStyle + "  font-bold py-2 focus:outline-none rounded"}
          onClick={() => dispatch(samePick())}
        >
          Same Picks
        </button>
        <button
          className={eraseStyle + "  font-bold py-2 focus:outline-none rounded"}
          onClick={() => dispatch(clear())}
        >
          Clear
        </button>
        <button
          className={playStyle + "  font-bold py-2 focus:outline-none rounded"}
          onClick={() => dispatch(play())}
        >
          {playLabel}
        </button>
      </div>
    </div>
  );
};

export default Controls;
