import React from "react";
import "./css/tailwind.css";
import { Card } from "./features/Card";
import Heading from "./features/Heading";
import PayTable from "./features/PayTable";
import Draws from "./features/Draws";
import Controls from "./features/Controls";
import Status from "./features/Status";
import History from "./features/History";
import Help from "./features/Help";

function App() {
  return (
    <div className="">
      <Heading />
      <div className="md:hidden text-center py-24">
        A larger display is required to play.
      </div>
      <div className="hidden md:flex w-11/12 lg:w-5/6 xl:w-2/3 mx-auto">
        <div className="w-1/5 bg-gray-100">
          <PayTable />
        </div>
        <div className="w-3/5">
          <Card />
        </div>
        <div className="w-1/5 bg-gray-100">
          <Draws />
        </div>
      </div>
      <div className="hidden md:flex w-11/12 lg:w-5/6 xl:w-2/3 mx-auto pt-5">
        <div className="w-1/5">
          <Status />
        </div>
        <div className="w-3/5">
          <Controls />
          <History />
        </div>
        <div className="w-1/5">
          <Help />
        </div>
      </div>
    </div>
  );
}

export default App;
