import { createSlice } from "@reduxjs/toolkit";
import getRandomNumbers from "../functions/getRandomNumbers";
import getPayTable from "../functions/getPayTable";
import initializeCard from "../functions/initializeCard";

export const slice = createSlice({
  name: "game",
  initialState: {
    value: initializeCard(),
    rounds: 0,
    spots: 0,
    hits: 0,
    draws: [],
    credits: 100,
    win: 0,
    results: "",
    status: "new",
    roundStatus: "new",
    history: []
  },
  reducers: {
    toggle: (state, action) => {
      if (state.status === "ready") return;
      if (state.status === "roundOver") return;
      if (state.status === "gameOver") return;
      const number = Number(action.payload.number) - 1;
      // Can only pick 10 spots maximum.
      if (state.spots === 10 && state.value[number].spot === false) {
        return;
      }
      // Otherwise, update the value and spots.
      if (state.value[number].spot) {
        state.spots--;
      } else {
        state.spots++;
      }
      state.value[number].spot = !state.value[number].spot;
      state.status = "picking";
    },
    clear: state => {
      if (state.status === "new") return;
      if (state.status === "gameOver") return;
      state.value = initializeCard();
      state.spots = 0;
      state.hits = 0;
      state.draws = [];
      state.results = "";
      state.status = state.roundStatus;
    },
    play: state => {
      if (state.status === "gameOver") {
        state.value = initializeCard();
        state.rounds = 0;
        state.spots = 0;
        state.hits = 0;
        state.draws = [];
        state.credits = 5;
        state.win = 0;
        state.results = "";
        state.status = "new";
        state.roundStatus = "new";
        state.history = [];
        return;
      }
      if (state.status === "new") return;
      if (state.status === "roundOver") return;
      if (state.status === "next") return;
      // Draw 20 random numbers and check for winners
      const drawnNumbers = getRandomNumbers(20);
      drawnNumbers.forEach((element, i) => {
        const number = Number(element) - 1;
        if (state.value[number].spot) {
          state.hits++;
        }
        const draw = { number: number + 1, hit: state.value[number].spot };
        state.draws.push(draw);
        state.value[number].drawn = true;
      });
      // Update the game history and status
      const win = getPayTable(state.spots, state.hits);
      state.win = win;
      state.credits = state.credits - 1 + win;
      state.results = win ? "WIN " + win : "LOSE";
      state.rounds++;
      const history = {
        spots: state.spots,
        hits: state.hits,
        results: state.results,
        credits: state.credits
      };
      state.history.push(history);
      if (state.credits <= 0) {
        state.status = "gameOver";
      } else {
        state.status = "roundOver";
        state.roundStatus = "next";
      }
    },
    quickPick: state => {
      if (state.status === "ready") return;
      if (state.status === "picking") return;
      if (state.status === "gameOver") return;
      state.value = initializeCard();
      state.spots = 0;
      state.hits = 0;
      state.draws = [];
      state.results = "";
      const picks = getRandomNumbers(10);
      picks.forEach(element => {
        const number = Number(element) - 1;
        state.value[number].spot = true;
        state.spots = 10;
      });
      state.status = "ready";
    },
    samePick: state => {
      if (state.status === "new") return;
      if (state.status === "ready") return;
      if (state.status === "picking") return;
      if (state.status === "next") return;
      if (state.status === "gameOver") return;
      state.hits = 0;
      state.draws = [];
      state.results = "";
      for (let number = 0; number < 80; number++) {
        state.value[number].drawn = false;
      }
      state.status = "ready";
    }
  }
});

export const selectCard = state => state.card.value;
export const selectRounds = state => state.card.rounds;
export const selectSpots = state => state.card.spots;
export const selectHits = state => state.card.hits;
export const selectDraws = state => state.card.draws;
export const selectCredits = state => state.card.credits;
export const selectResults = state => state.card.results;
export const selectStatus = state => state.card.status;
export const selectHistory = state => state.card.history;
export const { toggle, clear, play, quickPick, samePick } = slice.actions;

export default slice.reducer;
