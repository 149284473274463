import React from "react";
import { useSelector } from "react-redux";
import { selectCard, selectStatus } from "../slices/gameSlice";
import CardNumber from "./CardNumber";

export function Card() {
  const card = useSelector(selectCard);
  const status = useSelector(selectStatus);
  let cursor;
  switch (status) {
    case "new":
    case "next":
    case "picking":
      cursor = "cursor-pointer";
      break;

    default:
      cursor = "cursor-default";
      break;
  }

  return (
    <div className="px-5">
      <div className="grid grid-cols-10 gap-1 pb-5">
        {card.map((cardItem, i) => {
          if (i > 39) {
            return null;
          }
          return (
            <CardNumber className="" item={cardItem} cursor={cursor} key={i} />
          );
        })}
      </div>
      <div className="grid grid-cols-10 gap-1">
        {card.map((cardItem, i) => {
          if (i < 40) {
            return null;
          }
          return (
            <CardNumber className="" item={cardItem} cursor={cursor} key={i} />
          );
        })}
      </div>
    </div>
  );
}
