export default function getPayTable(spots, hits) {
  const payTables = [
    [0],
    [0, 2],
    [0, 0, 11],
    [0, 0, 2, 27],
    [0, 0, 1, 5, 75],
    [0, 0, 0, 2, 18, 420],
    [0, 0, 0, 1, 8, 50, 1100],
    [0, 0, 0, 1, 3, 17, 100, 4500],
    [0, 0, 0, 0, 2, 12, 50, 750, 10000],
    [0, 0, 0, 0, 1, 6, 25, 150, 3000, 30000],
    [5, 0, 0, 0, 0, 2, 15, 40, 450, 4250, 100000]
  ];

  if (hits === undefined) {
    return payTables[spots];
  }
  return payTables[spots][hits];
}
