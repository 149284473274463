import React from "react";
import { useSelector } from "react-redux";
import { selectStatus } from "../slices/gameSlice";

const Help = () => {
  const status = useSelector(selectStatus);
  let help;
  switch (status) {
    case "new":
    case "next":
      help =
        `<div>Pick between 1 and 10 numbers from the board.</div>` +
        `<div class="mt-2">QUICK PICK to have the computer pick 10 numbers for you.</div>` +
        `<div class="mt-2">Win by matching your numbers to the 20 numbers selected by the computer.</div>`;
      break;
    case "picking":
      help =
        `<div class="flex">` +
        `<div class="w-1/6 bg-gray-700"></div>` +
        `<div class="w-5/6 pl-2">Number has been picked by the player.</div>` +
        `</div>` +
        `<div class="flex mt-2">` +
        `<div class="w-1/6 bg-gray-100 border border-gray-700"></div>` +
        `<div class="w-5/6 pl-2">Number has not been picked by the player.</div>` +
        `</div>` +
        `<div class="mt-2">Pick between 1 and 10 numbers from the board.</div>` +
        `<div class="mt-2">CLEAR to erase the board so ` +
        `that you can pick different numbers.</div>` +
        `<div class="mt-2">PLAY to have the computer draw 20 winning numbers.<div> ` +
        `<div class="mt-2">Win by matching your numbers to the 20 numbers selected by the computer.</div>`;
      break;
    case "ready":
      help =
        `<div class="flex">` +
        `<div class="w-1/6 bg-gray-700"></div>` +
        `<div class="w-5/6 pl-2">Number has been picked by the player.</div>` +
        `</div>` +
        `<div class="flex mt-2">` +
        `<div class="w-1/6 bg-gray-100 border border-gray-700"></div>` +
        `<div class="w-5/6 pl-2">Number has not been picked by the player.</div>` +
        `</div>` +
        `<div class="mt-2">CLEAR to erase the board so ` +
        `that you can pick different numbers.</div>` +
        `<div class="mt-2">PLAY to have the computer draw 20 winning numbers.<div> ` +
        `<div class="mt-2">Win by matching your numbers to the 20 numbers selected by the computer.</div>`;
      break;
    case "roundOver":
      help =
        `<div>` +
        `<div class="flex">` +
        `<div class="w-1/6 bg-green-600"></div>` +
        `<div class="w-5/6 pl-2">Number drawn matches a player pick.</div>` +
        `</div>` +
        `<div class="flex mt-2">` +
        `<div class="w-1/6 bg-red-600"></div>` +
        `<div class="w-5/6 pl-2">Number drawn doesn't match a player pick.</div>` +
        `</div>` +
        `<div class="mt-2">Check the payout table to determine your winnings.</div>` +
        `<div class="mt-2">QUICK PICK to have the computer pick 10 new numbers for you.</div>` +
        `<div class="mt-2">PICK SAME to play the same numbers in the next round that you did in the last.</div>` +
        `<div class="mt-2">CLEAR to erase the board so ` +
        `that you can pick different numbers.</div>` +
        `</div>`;
      break;
    case "gameOver":
      help = `<div class="">NEW GAME to start a new game.</div>`;
      break;
    default:
      break;
  }

  return (
    <div className="w-full text-xs">
      <div dangerouslySetInnerHTML={{ __html: help }} />
    </div>
  );
};

export default Help;
