import React from "react";
import { useSelector } from "react-redux";
import { selectSpots, selectHits, selectResults } from "../slices/gameSlice";
import getPayTable from "../functions/getPayTable";

const PayTable = () => {
  const spots = useSelector(selectSpots);
  const hits = useSelector(selectHits);
  const results = useSelector(selectResults);
  const payTable = getPayTable(spots);
  const show = spots ? "block" : "hidden";
  let hightlightedRowColors;
  switch (results) {
    case "":
      hightlightedRowColors = "bg-gray-100";
      break;
    case "LOSE":
      hightlightedRowColors = "bg-red-600 text-white";
      break;
    default:
      hightlightedRowColors = "bg-green-600 text-white";
      break;
  }

  return (
    <div className={show + " text-sm px-5 pt-1"}>
      <div className="text-center pb-3">PAYOUTS</div>
      <div className="flex">
        <div className="w-1/2 text-left pl-2">HITS</div>
        <div className="w-1/2 text-right pr-2">WINS</div>
      </div>
      {payTable.map((item, i) => {
        const color = i === hits ? hightlightedRowColors : "";
        return (
          <div className={color + " flex"} key={i}>
            <div className="w-1/2 text-left pl-2">{i}</div>
            <div className="w-1/2 text-right pr-2">{item}</div>
          </div>
        );
      })}
    </div>
  );
};

export default PayTable;
