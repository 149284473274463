import React from "react";
import { useSelector } from "react-redux";
import {
  selectSpots,
  selectHits,
  selectCredits,
  selectResults,
  selectRounds,
  selectStatus
} from "../slices/gameSlice";

const Status = () => {
  const credits = useSelector(selectCredits);
  const status = useSelector(selectStatus);
  let results = useSelector(selectResults);
  let spots = useSelector(selectSpots);
  let hits = useSelector(selectHits);
  let rounds = useSelector(selectRounds);

  if (spots === 0) spots = "";
  if (hits === 0) hits = "";
  if (results === "") {
    results = "PLAY";
  }
  let resultsColor;
  if (results === "LOSE") {
    resultsColor = "text-red-600 border border-red-600";
  } else if (results !== "PLAY") {
    resultsColor = "text-green-600 border border-green-600";
  } else {
    resultsColor = "text-white border border-white";
  }
  const showGameEnd = status === "gameOver" ? "" : "hidden";

  return (
    <div className="w-full">
      <div className={resultsColor + " text-center p-2"}>{results}</div>
      <div className="pt-2">MARKED: {spots}</div>
      <div className="">HITS: {hits}</div>
      <div className="">BET: 1</div>
      <div className="">ROUNDS: {rounds}</div>
      <div className="">CREDITS: {credits}</div>
      <div
        className={
          showGameEnd +
          " text-xl text-red-600 text-center border border-red-600 py-6 mt-3"
        }
      >
        GAME OVER
      </div>
    </div>
  );
};

export default Status;
