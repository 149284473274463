import React from "react";
import { useDispatch } from "react-redux";
import { toggle } from "../slices/gameSlice";

const CardNumber = props => {
  const dispatch = useDispatch();
  const { item } = props;
  let color;
  if (!item.spot && !item.drawn) {
    color = "bg-gray-100 text-black";
  } else if (!item.spot && item.drawn) {
    color = "bg-red-600 text-white";
  } else if (item.spot && !item.drawn) {
    color = "bg-gray-700 text-white";
  } else if (item.spot && item.drawn) {
    color = "bg-green-600 text-white";
  }

  return (
    <button
      className={color + " " + props.cursor + " focus:outline-none p-1"}
      onClick={() => dispatch(toggle({ number: item.number }))}
    >
      {item.number}
    </button>
  );
};

export default CardNumber;
