export default function initializeCard() {
  let card = [];
  for (let i = 1; i < 81; i++) {
    card.push({
      number: i,
      spot: false,
      drawn: false
    });
  }
  return card;
}
