import React from "react";
import { useSelector } from "react-redux";
import { selectHistory } from "../slices/gameSlice";

const History = () => {
  const history = useSelector(selectHistory);
  const show = history.length ? "" : "hidden";

  return (
    <div className="w-full px-5 text-sm mx-auto mb-1 mt-5">
      <div className={show + " grid grid-cols-5 gap-1 text-sm mb-1"}>
        <div className="bg-gray-100 p-1">ROUND</div>
        <div className="bg-gray-100 p-1">MARKED</div>
        <div className="bg-gray-100 p-1">HITS</div>
        <div className="bg-gray-100 p-1">PAYOUT</div>
        <div className="bg-gray-100 p-1">CREDITS</div>
      </div>
      {history.map((item, i) => {
        return (
          <div className="grid grid-cols-5 gap-1 text-sm mb-1" key={i}>
            <div className="bg-gray-100 p-1">{i + 1}</div>
            <div className="bg-gray-100 p-1">{item.spots}</div>
            <div className="bg-gray-100 p-1">{item.hits}</div>
            <div className="bg-gray-100 p-1">{item.results}</div>
            <div className="bg-gray-100 p-1">{item.credits}</div>
          </div>
        );
      })}
    </div>
  );
};

export default History;
