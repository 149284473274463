import React from "react";
import { useSelector } from "react-redux";
import { selectStatus } from "../slices/gameSlice";

const Heading = () => {
  const status = useSelector(selectStatus);
  let colors1;
  let colors2;
  switch (status) {
    case "new":
    case "picking":
    case "ready":
    case "next":
      colors1 = "bg-gray-100";
      colors2 = "bg-gray-100";
      break;
    case "roundOver":
    case "gameOver":
      colors1 = "bg-red-600 text-white";
      colors2 = "bg-green-600 text-white";
      break;
    default:
      break;
  }

  return (
    <div className="flex justify-center font-bold my-2">
      <div className={colors1 + " py-6 px-8 mx-1 rounded-full"}>K</div>
      <div className={colors2 + " py-6 px-8 mx-1 rounded-full"}>E</div>
      <div className={colors1 + " py-6 px-8 mx-1 rounded-full"}>N</div>
      <div className={colors2 + " py-6 px-8 mx-1 rounded-full"}>O</div>
    </div>
  );
};

export default Heading;
