export default function getRandomNumbers(size) {
  const numbers = [];
  for (let index = 1; index < 81; index++) {
    numbers.push(index);
  }
  // Shuffle
  for (let i = 79; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = numbers[i];
    numbers[i] = numbers[j];
    numbers[j] = temp;
  }
  // Return the requested number of items
  return numbers.slice(0, size);
}
