import React from "react";
import { useSelector } from "react-redux";
import { selectDraws } from "../slices/gameSlice";

const Draws = () => {
  const draws = useSelector(selectDraws);

  const show = draws.length ? "" : "hidden";

  return (
    <div className="px-5 pt-2 text-sm">
      <div className={show + " text-center pb-2"}>WINNERS</div>
      <div className="grid grid-cols-2 gap-1 ">
        {draws.map((item, i) => {
          const color = item.hit
            ? "bg-green-600 text-white"
            : "bg-red-600 text-white";
          return (
            <div className={color + " text-center"} key={i}>
              {item.number}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Draws;
